/*-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01.COMMON
    02.HEADER
    03.BRAND
    04.SERVICES
    05.ABOUT
    06.VIDEO COUNTER
    07.TEAM
    08.TESTIMONIAL
    09.BLOG
    10.SUBSCRIBE
    11.FOOTER

-----------------------------------------------------------------------------------*/
/*===========================
     01.COMMON css
===========================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700|Lato:300,400,700&display=swap");
body {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #6a6972;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  margin: 0px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6a6972;
  margin: 0px;
}

.main_bg {
  background-image: url(../../assets/images/banner-bg.svg);
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
  outline: 0;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 25px;
  font-size: 16px;
  line-height: 48px;
  border-radius: 8px;
  border: 0;
  color: #fff;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background: -webkit-linear-gradient(
    left,
    #33c8c1 0%,
    #119bd2 50%,
    #33c8c1 100%
  );
  background: -o-linear-gradient(left, #33c8c1 0%, #119bd2 50%, #33c8c1 100%);
  background: linear-gradient(to right, #33c8c1 0%, #119bd2 50%, #33c8c1 100%);
  background-size: 200%;
}

/*===== All SECTION TITLE Style =====*/
.section-title .line {
  background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
  width: 150px;
  height: 5px;
  margin-bottom: 10px !important;
}

.section-title .title {
  font-size: 38px;
  font-weight: 700;
  line-height: 42px;
}
@media (max-width: 767px) {
  .section-title .title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
  }
}
.section-title .title span {
  font-weight: 400;
  display: contents;
}

/*===== All Section Title Style =====*/
.section-title .sub-title {
  font-size: 18px;
  font-weight: 400;
  color: #361cc1;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-title .sub-title {
    font-size: 16px;
  }
}

.section-title .title {
  font-size: 32px;
  padding-top: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .section-title .title {
    font-size: 24px;
  }
}
.section-title .title span {
  font-weight: 400;
  display: contents;
}

/*===== All Slick Slide Outline Style =====*/
.slick-slide {
  outline: 0;
}

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999;
}
.preloader .loader {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.preloader .loader .ytp-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  margin-left: -32px;
  z-index: 18;
  pointer-events: none;
}
.preloader .loader .ytp-spinner .ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -moz-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -o-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
}
.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -moz-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -o-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}
.preloader
  .loader
  .ytp-spinner
  .ytp-spinner-container
  .ytp-spinner-rotator
  .ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 50%;
}
.preloader
  .loader
  .ytp-spinner
  .ytp-spinner-container
  .ytp-spinner-rotator
  .ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  left: 50%;
}
.preloader .loader .ytp-spinner-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  /* Spinner Color */
  border-color: #361cc1 #361cc1 #e1e1e1;
  border-radius: 50%;
  border-width: 6px;
}
.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: #e1e1e1;
  -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -moz-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -o-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}
.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: #e1e1e1;
  -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  -moz-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  -o-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

/*===========================
      02.HEADER css
===========================*/
/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sticky .navbar {
  padding: 10px 0;
}

.navbar {
  padding: 25px 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding: 0;
}

.navbar-toggler {
  padding: 0;
}
.navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #fff;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}
.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}
.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px;
  }
}

.navbar-nav .nav-item {
  margin-right: 45px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .navbar-nav .nav-item {
    margin: 0;
  }
}
.navbar-nav .nav-item a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 0;
  position: relative;
  font-family: "Poppins", sans-serif;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item a {
    display: block;
    padding: 4px 0;
    color: #2e2e2e;
  }
}
@media (max-width: 767px) {
  .navbar-nav .nav-item a {
    display: block;
    padding: 4px 0;
    color: #2e2e2e;
  }
}
.navbar-nav .nav-item:hover .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}
@media (max-width: 767px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}
.navbar-nav .nav-item .sub-menu {
  width: 200px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 110%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: none;
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: none;
    opacity: 1;
    visibility: visible;
  }
}
.navbar-nav .nav-item .sub-menu li {
  display: block;
}
.navbar-nav .nav-item .sub-menu li a {
  display: block;
  padding: 8px 20px;
  color: #2e2e2e;
}
.navbar-nav .nav-item .sub-menu li a.active,
.navbar-nav .nav-item .sub-menu li a:hover {
  padding-left: 25px;
  color: #361cc1;
}

.navbar-nav .sub-nav-toggler {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #2e2e2e;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 767px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #2e2e2e;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}
.navbar-nav .sub-nav-toggler span {
  width: 8px;
  height: 8px;
  border-left: 1px solid #2e2e2e;
  border-bottom: 1px solid #2e2e2e;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-btn {
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .navbar-btn {
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.navbar-btn .main-btn {
  height: 45px;
  line-height: 45px;
  background: -webkit-linear-gradient(
    left,
    #fe8464 0%,
    #fe6e9a 50%,
    #fe8464 100%
  );
  background: -o-linear-gradient(left, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
}
.navbar-btn .main-btn:hover {
  color: #fff;
  background-position: right center;
}

.sticky .navbar-toggler .toggler-icon {
  background-color: #2e2e2e;
}

.sticky .navbar-nav .nav-item a {
  color: #2e2e2e;
}

.sticky .navbar-nav .nav-item.active > a,
.sticky .navbar-nav .nav-item:hover > a {
  color: #361cc1;
}
/*===== FORMS =====*/

.form-submit-btn:hover {
  opacity: 0.8;
}

.form-submit-btn {
  margin-top: 5px;
  padding: 5px;
  border-radius: 1px;
}

input[type="username"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/*===== HEADER HERO =====*/
.header-hero {
  position: relative;
  z-index: 5;
  background-position: bottom center;
}

#particles-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.header-hero-content {
  padding-top: 180px;
}
@media (max-width: 767px) {
  .header-hero-content {
    padding-top: 100px;
  }
}
.header-hero-content .header-sub-title {
  font-size: 38px;
  font-weight: 300;
  color: #fff;
}
@media (max-width: 767px) {
  .header-hero-content .header-sub-title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-hero-content .header-sub-title {
    font-size: 30px;
  }
}
.header-hero-content .header-title {
  font-size: 38px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 24px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-hero-content .header-title {
    font-size: 30px;
  }
}
.header-hero-content .text {
  color: #fff;
  margin-top: 30px;
}
.header-hero-content .main-btn {
  margin-top: 40px;
}

.header-hero-image {
  padding-top: 45px;
}
.header-hero-image img {
  max-width: 650px;
}
@media (max-width: 767px) {
  .header-hero-image img {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .header-hero-image {
    padding-top: 30px;
  }
}

/*===========================
      03.BRAND css
===========================*/
@media (max-width: 767px) {
  .brand-logo {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.single-logo {
  padding: 0 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-logo {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .single-logo {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .single-logo {
    width: 50%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-logo {
    width: 33.33%;
  }
}
.single-logo img {
  width: 100%;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-logo:hover img {
  opacity: 1;
}

/*===========================
      04.SERVICES css
===========================*/
.single-services {
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
  -moz-box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
  box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 50px 30px;
  border: 2px solid transparent;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .single-services {
    padding: 20px 20px 30px;
  }
}
.single-services .services-icon {
  display: inline-block;
  position: relative;
}
.single-services .services-icon img {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-services .services-icon .shape-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.single-services .services-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 34px;
  color: #fff;
}
.single-services .services-content .services-title a {
  font-size: 26px;
  font-weight: 700;
  color: #2e2e2e;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-services .services-content .services-title a {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .single-services .services-content .services-title a {
    font-size: 20px;
  }
}
.single-services .services-content .services-title a:hover {
  color: #361cc1;
}
.single-services .services-content .text {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .single-services .services-content .text {
    margin-top: 20px;
  }
}
.single-services .services-content .more {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-services .services-content .more i {
  margin-left: 10px;
}
.single-services .services-content .more:hover {
  color: #361cc1;
}
.single-services:hover {
  border-color: #fe7a7b;
}
.single-services:hover .services-icon .shape {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
}

/*===========================
      05.ABOUT css
===========================*/
.about-area {
  position: relative;
  z-index: 5;
}

.about-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  z-index: -1;
}
.about-shape-1 img {
  width: 100%;
}
@media (max-width: 767px) {
  .about-shape-1 {
    display: none;
  }
}

.about-shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
  height: 100%;
  z-index: -1;
}
.about-shape-2 img {
  width: 100%;
}
@media (max-width: 767px) {
  .about-shape-2 {
    display: none;
  }
}

.about-content {
  max-width: 480px;
}
.about-content .text {
  margin-top: 15px;
}
.about-content .main-btn {
  background: -webkit-linear-gradient(
    left,
    #fe8464 0%,
    #fe6e9a 50%,
    #fe8464 100%
  );
  background: -o-linear-gradient(left, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
  margin-top: 40px;
}
.about-content .main-btn:hover {
  background-position: right center;
}

/*===========================
    06.VIDEO COUNTER css
===========================*/
.video-content {
  position: relative;
  padding-bottom: 30px;
}
.video-content .dots {
  position: absolute;
  left: -30px;
  bottom: 0;
}

.video-wrapper {
  margin-right: 15px;
  position: relative;
  -webkit-box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
  -moz-box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
  box-shadow: 0px 13px 46px 0px rgba(113, 113, 113, 0.33);
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .video-wrapper {
    margin-right: 0;
  }
}
.video-wrapper .video-image img {
  width: 100%;
}
.video-wrapper .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(54, 28, 193, 0.2) 0%,
    rgba(46, 130, 239, 0.2) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(54, 28, 193, 0.2) 0%,
    rgba(46, 130, 239, 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(54, 28, 193, 0.2) 0%,
    rgba(46, 130, 239, 0.2) 100%
  );
}
.video-wrapper .video-icon a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #fe8464;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.counter-wrapper {
  padding-left: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .counter-wrapper {
    padding-left: 0;
  }
}
.counter-wrapper .counter-content .text {
  margin-top: 35px;
}
.counter-wrapper .single-counter {
  max-width: 155px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  z-index: 5;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter::before {
  position: absolute;
  content: "";
  width: 155px;
  height: 120px;
  border-radius: 60px;
  top: 0;
  left: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  z-index: -1;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 100px;
    height: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter::before {
    max-width: 155px;
    height: 120px;
  }
}
.counter-wrapper .single-counter.counter-color-1::before {
  background: -webkit-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: -o-linear-gradient(#33c8c1 0%, #119bd2 100%);
  background: linear-gradient(#33c8c1 0%, #119bd2 100%);
}
.counter-wrapper .single-counter.counter-color-2::before {
  background: -webkit-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: -o-linear-gradient(#fe8464 0%, #fe6e9a 100%);
  background: linear-gradient(#fe8464 0%, #fe6e9a 100%);
}
.counter-wrapper .single-counter.counter-color-3::before {
  background: -webkit-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(#361cc1 0%, #2e82ef 100%);
  background: linear-gradient(#361cc1 0%, #2e82ef 100%);
}
.counter-wrapper .single-counter .count {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .count {
    font-size: 22px;
  }
}
.counter-wrapper .single-counter .text {
  font-size: 16px;
  color: #fff;
}
@media (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-wrapper .single-counter .text {
    font-size: 16px;
  }
}

/*===========================
       10.SUBSCRIBE  css
===========================*/
.subscribe-area {
  padding: 40px 50px 90px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  -moz-box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  box-shadow: 0px 0px 18px 0px rgba(50, 77, 215, 0.14);
  border-radius: 10px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .subscribe-area {
    padding: 10px 30px 60px;
  }
}

.subscribe-content .subscribe-title {
  font-size: 35px;
  font-weight: 700;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe-content .subscribe-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .subscribe-content .subscribe-title {
    font-size: 24px;
  }
}
.subscribe-content .subscribe-title span {
  font-weight: 400;
}

.subscribe-form {
  position: relative;
}
.subscribe-form input {
  border: 2px solid #e1e1e1;
  height: 65px;
  padding: 0 30px;
  border-radius: 5px;
  width: 100%;
  color: #2e2e2e;
}
.subscribe-form input::placeholder {
  opacity: 1;
  color: #b9b9b9;
}
.subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #b9b9b9;
}
.subscribe-form input::-moz-placeholder {
  opacity: 1;
  color: #b9b9b9;
}
.subscribe-form input::-webkit-input-placeholder {
  opacity: 1;
  color: #b9b9b9;
}
.subscribe-form input:focus {
  border-color: #361cc1;
}
.subscribe-form button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 65px;
  line-height: 65px;
  background: -webkit-linear-gradient(
    left,
    #fe8464 0%,
    #fe6e9a 50%,
    #fe8464 100%
  );
  background: -o-linear-gradient(left, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
  background-size: 200%;
}
@media (max-width: 767px) {
  .subscribe-form button {
    position: relative;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .subscribe-form button {
    position: absolute;
    width: auto;
    margin-top: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

button:not(:disabled):hover {
  background-position: right center;
}

/*===========================
       11.FOOTER  css
===========================*/
.footer-area {
  background-position: top center;
  position: relative;
  z-index: 5;
}

.footer-area::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../images/footer-bg.svg);
  background-position: top center;
  z-index: -1;
  background-size: cover;
}
@media only screen and (min-width: 1921px) {
  .footer-area::before {
    height: 1120px;
  }
}

#particles-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.footer-widget {
  padding-top: 50px;
}

.footer-about .logo img {
  width: 160px;
}

.footer-about .text {
  color: #fff;
  line-height: 30px;
  margin-top: 30px;
}

.footer-about .social {
  margin-top: 40px;
}
.footer-about .social li {
  display: inline-block;
  margin-right: 30px;
}
.footer-about .social li a {
  font-size: 22px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.footer-about .social li a:hover {
  color: #fe8464;
}

.footer-title .title {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.link-wrapper {
  padding: 0 35px;
}
@media (max-width: 767px) {
  .link-wrapper {
    padding: 0;
    padding-right: 50px;
  }
}
.link-wrapper .link {
  padding-top: 20px;
}
.link-wrapper .link li {
  margin-top: 20px;
}
.link-wrapper .link li a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.link-wrapper .link li a:hover {
  color: #fe8464;
}

.footer-contact .contact {
  padding-top: 20px;
}
.footer-contact .contact li {
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}

.footer-copyright {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 15px;
  padding-bottom: 30px;
}

.copyright-content {
  padding-top: 15px;
  text-align: center;
}
.copyright-content p {
  color: #fff;
}
.copyright-content a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.copyright-content a:hover {
  color: #fe8464;
}

/*===== BACK TO TOP =====*/
.back-to-top {
  font-size: 20px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #361cc1 0%, #2e82ef 100%);
  background: -o-linear-gradient(left, #361cc1 0%, #2e82ef 100%);
  background: linear-gradient(to right, #361cc1 0%, #2e82ef 100%);
  text-align: center;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  display: none;
}
.back-to-top:hover {
  color: #fff;
}
